import { columns2 as columns } from "./config";
import DataStatisticsApi from "@/api/dc/dataStatistics";
import TableDataStatistics from "./components/TableDataStatistics.vue";
export default {
  name: "QualityStandardOperation",
  components: {
    TableDataStatistics: TableDataStatistics
  },
  data: function data() {
    return {
      columns: columns,
      getListFn: DataStatisticsApi.getTaggingStatisticsList
    };
  }
};